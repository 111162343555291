import { useContext, useEffect, useRef, useState } from "react";
import { HomeContext, useHomeContext } from "../../contexts/HomeContext";
import toast from "react-hot-toast";
import { NavLink } from "react-router-dom";

export default function Home() {
  const { notes, page } = useHomeContext();
  const { getHome, setPage, setNotes } = useContext(HomeContext);

  const homeRef = useRef(null);
  const statisticsRef = useRef(null);
  const coinMarketRef = useRef(null);
  const dataAnalysisRef = useRef(null);

  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: 'smooth', passive: true });
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // التحميل البطيء للملفات الجافا سكربت
    const loadScript = (src) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = false;
      document.body.appendChild(script);
    }

    loadScript('./style1/js/jquery-3.3.1.min.js');
    loadScript('./style1/js/method-assign.js');
    loadScript('./style1/js/map-shortcode.js');
    loadScript('./style1/js/js-plugins/crum-mega-menu.js');
    loadScript('./style1/js/theme-plugins.js');
    loadScript('./style1/js/js-plugins/isotope.pkgd.min.js');
    loadScript('./style1/js/js-plugins/ajax-pagination.js');
    loadScript('./style1/js/js-plugins/swiper.min.js');
    loadScript('./style1/js/js-plugins/material.min.js');
    loadScript('./style1/js/js-plugins/orbitlist.js');
    loadScript('./style1/js/js-plugins/bootstrap-datepicker.js');
    loadScript('./style1/fonts/fontawesome-all.js');
    loadScript('./style1/js/main.js');
  }, [])

  return (
    <div className="homePage">
      <header className="header" id="site-header">
      
        <div className="container">
          <div className="header-content-wrapper flex">
            <a href="/" className="site-logo">
              <img src="logo/logo_name.png" alt="Digital Money" />
            </a>
            <nav id="primary-menu" className={`primary-menu ${isOpen ? 'open' : ''}`}>
              <a href='#' id="menu-icon-trigger" className="menu-icon-trigger showhide" onClick={toggleMenu}>
                <span className="mob-menu--title">
                  {isOpen ? (
                    <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                    </svg>
                  ) : (
                    <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M3 6h18v2H3zm0 5h18v2H3zm0 5h18v2H3z" />
                    </svg>
                  )}
                </span>
              </a>
              <ul className="primary-menu-menu">
                <li>
                  <a href="#" onClick={(e) => { e.preventDefault(); scrollToSection(homeRef); }} >
                    الرئيسية
                  </a>
                </li>
                <li>
                  <a href="#" onClick={(e) => { e.preventDefault(); scrollToSection(statisticsRef); }} >
                    الخبراء
                  </a>
                </li>
                <li>
                  <a href="#" onClick={(e) => { e.preventDefault(); scrollToSection(coinMarketRef); }} >
                    سوق العملات
                  </a>
                </li>
                <li>
                  <a href="#" onClick={(e) => { e.preventDefault(); scrollToSection(dataAnalysisRef); }} >
                    تحليل البيانات
                  </a>
                </li>
                <li>
                  <NavLink to="/register" className="text-yellow-500">
                    سجل الان
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/login" className="text-yellow-500">
                    دخول
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
      {/* <!-- ... end Header --> */}
 
      <div className="main-content-wrapper">
        <section ref={homeRef} data-settings="particles-1" class="main-section crumina-flying-balls particles-js bg-1">
          <div class="container">
            <div class="row medium-padding120 align-center">
              <div class="col-lg-8 col-lg-offset-2 col-md-12 col-sm-12 col-xs-12">
                <header class="crumina-module crumina-heading heading--h2 heading--with-decoration">
                  <div class="heading-sup-title" style={{ fontSize: "2.5rem" }}>خطوة إلى المستقبل المالي</div>
                  <h2 class="heading-title heading--half-colored">استثمر في عالم العملات المشفرة</h2>
                  <div class="heading-text">
                    انضم إلى قافلة المستثمرين الذين يحققون ثروات هائلة من خلال الاستثمار في العملات المشفرة. هذه الفرصة الفريدة لا تتكرر، فاغتنمها الآن قبل أن تفوتك وحقق الاستقلال المالي الذي طالما حلمت به.
                  </div>
                </header>
                <NavLink to="/register" class="btn btn--large btn--secondary btn--transparent">ابدأ الربح</NavLink>
              </div>
            </div>
          </div>
        </section>


        <section ref={statisticsRef} className="medium-padding120 responsive-align-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <header className="crumina-module crumina-heading heading--h2 heading--with-decoration">
                  <div className="heading-sup-title">إحصائيات العملات المشفرة</div>
                  <h2 className="heading-title weight-normal">
                    متابعة إحصائيات العملات على
                    <span className="weight-bold">جميع أجهزتك</span>
                  </h2>
                  <div className="heading-text">
                    استمتع بمتابعة أحدث البيانات الإحصائية للعملات المشفرة الرائدة على كافة أجهزتك المحمولة والثابتة.
                  </div>
                </header>

                <p>
                  تتيح لك منصة "Digital Money" إمكانية متابعة إحصائيات العملات المشفرة على مدار الساعة بكل سهولة ويسر، ما يساعدك على اتخاذ قرارات استثمارية مدروسة.
                </p>

                <div className="btn-market-wrap mt60"> 
                   
                </div>
              </div>

              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mt30">
                <img className="responsive-width-50" src="style1/img/phone.png" alt="هاتف" />
              </div>
            </div>
          </div>
        </section>

        <section ref={coinMarketRef} className="bg-dotted-map">
          <div className="container">
            <div className="row medium-padding300 align-center">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <img className="primary-dots mb30" src="style1/img/dots.png" alt="نقاط" />

                <header className="crumina-module crumina-heading heading--h2 heading--with-decoration">
                  <h2 className="heading-title weight-normal text-yellow-500">
                    الحياة في عالم
                    <span className="weight-bold  text-yellow-500">الرقمي</span>
                  </h2>
                  <div className="heading-text">تقنية البلوكشين</div>
                </header>

                <div className="counters">
                  <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                    <div className="crumina-module crumina-counter-item">
                      <div className="counter-numbers counter">
                        <span data-speed="2000" data-refresh-interval="3" data-to="6386" data-from="2"></span>
                      </div>
                      <h4 className="counter-title">سعر السوق</h4>
                      <p className="counter-text">تُظهر التحقيقات أن القراء يقرؤون بشكل أفضل النص واضح، مما يعني أن الوضوح أمر حيوي.</p>
                      <div className="counter-line"></div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                    <div className="crumina-module crumina-counter-item">
                      <div className="counter-numbers counter">
                        <span data-speed="2000" data-refresh-interval="3" data-to="16" data-from="2">16</span>
                        <div className="units">ميغابايت</div>
                      </div>
                      <h4 className="counter-title">متوسط حجم الكتلة</h4>
                      <p className="counter-text">حجم الكتلة يؤثر على سرعة المعاملات والتسوية، مما يؤكد أهمية تقنية البلوكشين.</p>
                      <div className="counter-line"></div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                    <div className="crumina-module crumina-counter-item">
                      <div className="counter-numbers counter">
                        <span data-speed="2000" data-refresh-interval="3" data-to="8327" data-from="2"></span>
                      </div>
                      <h4 className="counter-title">عملاء حول العالم</h4>
                      <p className="counter-text">تلعب قاعدة العملاء الواسعة دورًا حيويًا في نمو واعتماد تقنية البلوكشين.</p>
                      <div className="counter-line"></div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                    <div className="crumina-module crumina-counter-item">
                      <div className="counter-numbers counter">
                        <span data-speed="2000" data-refresh-interval="3" data-to="2000" data-from="2"></span>
                        <div className="units">+</div>
                      </div>
                      <h4 className="counter-title">المعاملات</h4>
                      <p className="counter-text">البلوكشين تمكن من إتمام المعاملات بشكل آمن وشفاف.</p>
                      <div className="counter-line"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section ref={dataAnalysisRef} className="bg-1">
          <div className="container">
            <div className="row medium-padding120">
              <div className="crumina-module crumina-featured-block">
                <div className="image-block">
                  <img src="style1/img/pc.png" alt="جهاز كمبيوتر" />
                </div>
                <div >
                  <header className="crumina-module crumina-heading heading--h2 heading--with-decoration">
                    <h2 className="heading-title weight-normal text-yellow-500 ">
                      الوصول إلى
                      <span className="weight-bold text-yellow-500 ">بيانات السوق والأسعار التحليلية</span>
                    </h2>
                    <div class="heading-text">
                      تمنحك منصة "Digital Money" إمكانية الوصول السهل والسريع إلى جميع البيانات الإحصائية والتحليلية المتعلقة بأداء العملات المشفرة، ما يساعدك على اتخاذ قرارات استثمارية مستنيرة.
                    </div>
                  </header>
                </div>
              </div>
            </div>

            <div className="row pb100">
              <div className="col-lg-12 col-md-12 col0sm-12 col-xs-12">
                <div className="crumina-module crumina-module-slider clients--slider navigation-center-both-sides">
                  <div className="swiper-btn-next">
                  </div>

                  <div className="swiper-btn-prev">
                    <svg className="BigBtc-icon icon-line-arrow-left">
                    </svg>
                  </div>

                  <div className="row">
                    <div className="col-lg-10 col-lg-offset-1 col-md-12 col-sm-12 col-xs-12">
                      <div className="swiper-container" data-show-items="5" data-prev-next="1">
                        <div className="swiper-wrapper">
                          <div className="swiper-slide">
                            <a className="clients-item" href="#">
                              <img src="style1/img/client1.png" className="" alt="شعار" />
                            </a>
                          </div>
                          <div className="swiper-slide">
                            <a className="clients-item" href="#">
                              <img src="style1/img/client2.png" className="" alt="شعار" />
                            </a>
                          </div>
                          <div className="swiper-slide">
                            <a className="clients-item" href="#">
                              <img src="style1/img/client3.png" className="" alt="شعار" />
                            </a>
                          </div>
                          <div className="swiper-slide">
                            <a className="clients-item" href="#">
                              <img src="style1/img/client4.png" className="" alt="شعار" />
                            </a>
                          </div>
                          <div className="swiper-slide">
                            <a className="clients-item" href="#">
                              <img src="style1/img/client5.png" className="" alt="شعار" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="divider" />
          </div>
        </section>


      </div>

      {/* <!-- Footer --> */}

      <footer id="site-footer" className="footer">
        <canvas id="can"></canvas>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-lg-offset-3 col-md-6 col-md-offset-3 col-sm-12 col-sm-offset-0 col-xs-12">
              <div className="widget w-info">
                <a href="/" className="site-logo">
                  <img src="logo/logo_name.png" alt="Digital Money" />
                </a>
                <p>
                  تهدف منصة Digital Money إلى توفير بيئة آمنة وشفافة للتعامل مع العملات المشفرة، حيث تلتزم بأعلى المعايير الأخلاقية والمهنية في تقديم الخدمات والمعلومات.
                </p>
              </div>
              <div className="footer-links">
              <h4>روابط سريعة</h4>
              <ul>
                <li><NavLink to="/about-us">من نحن</NavLink></li>
                 <li><NavLink to="/privacy-policy">سياسة الخصوصية</NavLink></li>
                <li><NavLink to="/terms-of-use">الشروط والأحكام</NavLink></li>
                <li><NavLink to="/connect-us">تواصل معنا</NavLink></li>
              </ul>
            </div>
              <div className="widget w-contacts">
                <ul className="socials socials--white">
                  <li className="social-item">
                    <a href="#">
                      <i className="fab fa-twitter BigBtc-icon"></i>
                    </a>
                  </li>
                  <li className="social-item">
                    <a href="#">
                      <i className="fab fa-instagram BigBtc-icon"></i>
                    </a>
                  </li>
                  <li className="social-item">
                    <a href="#">
                      <i className="fab fa-linkedin-in BigBtc-icon"></i>
                    </a>
                  </li>
                  <li className="social-item">
                    <a href="#">
                      <i className="fab fa-facebook-square BigBtc-icon"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <a className="back-to-top left-1/2 -translate-x-1/2 flex items-center justify-center" href="#">
          <svg className="back-to-top-svg text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor" d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z" />
          </svg>
        </a>
      </footer>
    </div>

  );
}
