import { FaSun, FaMoon } from 'react-icons/fa';

const DarkModeToggle = ({ isDarkMode, setIsDarkMode }) => {
  return (
    <button
      id="theme-toggle"
      type="button"
      className="mx-4  bg-gray-100 dark:bg-slate-700   focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5"
      onClick={() => setIsDarkMode(!isDarkMode)}
    >
      {isDarkMode ? <FaSun size={20} className='text-yellow-400 ' /> : <FaMoon size={20} className='text-slate-700  ' />}
    </button>
  );
};

export default DarkModeToggle;