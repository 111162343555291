"use client";
import { useContext, useEffect, useState } from "react";
import { AuthContext, useAuthContext } from "../../contexts/AuthContext";

import { Toaster, toast } from "react-hot-toast";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { BsSendFill } from "react-icons/bs";
import { Link, NavLink, useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { loginApi } = useContext(AuthContext);
  const { userLoggedIn } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (userLoggedIn)  window.location.href = "/dashboard";
  }, []);

  const handleLogin = async () => {
    try {
      setLoading(true);
      const response = await loginApi(email, password);
      setLoading(false);
      if (response.data.status) {
        toast.success(response.data.message);
        // router.push('/dashboard');
          window.location.href = "/dashboard";
      } else {
        toast.error(response.data.message);
      }
      // console.log(response.data.status);
      // location.href = '/dashboard';
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.response && error.response.status === 401) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while logging in. Please try again.");
      }
    }
  };

  return (
    <>
      <div className="container sticky top-0 z-sticky">
        <div className="flex flex-wrap -mx-3">
          <div className="w-full max-w-full px-3 flex-0">
            <nav className="absolute top-0 left-0 right-0 z-30 flex flex-wrap items-center px-4 py-2 m-6 mb-0 shadow-sm rounded-xl bg-white/80 backdrop-blur-2xl backdrop-saturate-200 lg:flex-nowrap lg:justify-start">
              <div className="flex items-center justify-between w-full p-0 px-6 mx-auto flex-wrap-inherit">
              <NavLink to="/"  className="py-1.75 text-sm mr-4 ml-4 whitespace-nowrap font-bold text-slate-700 lg:ml-0" > 
              Digital Money
               </NavLink>
                 
                      <NavLink to="/register" className="block px-4 py-2 mr-2 font-normal transition-all ease-in-out lg-max:opacity-0 duration-250 text-sm text-slate-700 lg:px-2" >
                      <i className="mr-1 fas fa-user-circle opacity-60"></i> سجل الان
                        </NavLink> 
              </div>
            </nav>
          </div>
        </div>
      </div>
      <main className="mt-0 transition-all duration-200 ease-in-out">
        <section>
          <div className="relative flex items-center min-h-screen p-0 overflow-hidden bg-center bg-cover">
            <div className="container z-1">
              <div className="flex flex-wrap -mx-3">
                <div className="flex flex-col w-full max-w-full px-3 mx-auto lg:mx-0 shrink-0 md:flex-0 md:w-7/12 lg:w-5/12 xl:w-4/12">
                  <div className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none lg:py4 dark:bg-gray-950 rounded-2xl bg-clip-border">
                    <div className="p-6 pb-0 mb-0">
                      <h4 className="font-bold">تسجيل الدخول</h4>
                      <p className="mb-0">ادخل الايميل وكلمة السر</p>
                    </div>
                    <div className="flex-auto p-6">
                      <form role="form">
                        <div className="mb-4">
                          <input className="focus:shadow-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                            autoComplete="off"
                            onChange={(e) => setEmail(e.target.value)}
                            id="email"
                            name="email"

                          />
                        </div>

                        <div className="mb-4">
                          <input
                            autoComplete="off"
                            onChange={(e) => setPassword(e.target.value)}
                            id="password"
                            name="password"
                            type="password" placeholder="Password" className="focus:shadow-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none" />

                        </div>

                        <div className="text-center">
                          <LoadingButton
                            onClick={handleLogin}
                            loading={loading} 
                            variant="contained"
                            color="warning"
                            className="inline-block w-full px-16 py-3.5 mt-6 mb-0 font-bold leading-normal text-center text-white align-middle transition-all bg-blue-500 border-0 rounded-lg cursor-pointer hover:-translate-y-px active:opacity-85 hover:shadow-xs text-sm ease-in tracking-tight-rem shadow-md bg-150 bg-x-25"
                          >
                            دخول
                          </LoadingButton>
                        </div>
                      </form>
                    </div>
                    <div className="border-black/12.5 rounded-b-2xl border-t-0 border-solid p-6 text-center pt-0 px-1 sm:px-6">
                      <p className="mx-auto mb-6 leading-normal text-sm">لاتمتلك حساب ؟
                        <NavLink to="/register" className="text-blue-500">
                          سجل الان
                        </NavLink>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="absolute top-0 left-0 flex-col justify-center hidden w-6/12 h-full max-w-full px-3 pr-0 my-auto text-center flex-0 lg:flex">
                  <div className="relative flex flex-col justify-center h-full bg-cover px-24 m-4 overflow-hidden bg-[url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg')] rounded-xl ">
                    <span className="absolute top-0 left-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-blue-500 to-violet-500 opacity-60"></span>
                    <h4 className="z-20 mt-12 font-bold text-white">"الربح هو العملة الجديدة"</h4>
                    <p className="z-20 text-white ">
                    كلما بدا الموقع اسهل، زاد الجهد الذي بذل في بناءه. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main> 

      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default Login;
