import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { BsSendFill } from 'react-icons/bs';
import { AuthContext } from '../../contexts/AuthContext';
import api from '../../axios/api';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';


function Register(props) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const { RegisterApi } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const referralCode = queryParams.get('referral_code');
    if (referralCode) {
      setReferralCode(referralCode);
    }
  }, []);

  const handleRegister = async () => {
    setLoading(true);
    const response = await RegisterApi(name, email, password, referralCode);
    setLoading(false);
    if (response.data.status) {
      toast.success(response.data.message);
      window.location.href = '/dashboard';
    } else {
      toast.error(response.data.message);
    }
  };

  const isNameValid = name.length >= 4;
  const isPasswordValid = password.length >= 8;

  return (
    <>
      <div class="container sticky top-0 z-sticky">
        <div class="flex flex-wrap -mx-3">
          <div class="w-full max-w-full px-3 flex-0">
            <nav class="absolute top-0 left-0 right-0 z-30 flex flex-wrap items-center px-4 py-2 m-6 mb-0 shadow-sm rounded-xl bg-white/80 backdrop-blur-2xl backdrop-saturate-200 lg:flex-nowrap lg:justify-start">
              <div class="flex items-center justify-between w-full p-0 px-6 mx-auto flex-wrap-inherit">
                <NavLink to="/" class="py-1.75 text-sm mr-4 ml-4 whitespace-nowrap font-bold text-slate-700 lg:ml-0" >
                Digital Money
                </NavLink>

                <NavLink to="/login" className="block px-4 py-2 mr-2 font-normal transition-all ease-in-out lg-max:opacity-0 duration-250 text-sm text-slate-700 lg:px-2" >
                  <i class="mr-1 fas fa-user-circle opacity-60"></i>   تسجيل الدخول
                </NavLink>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <main class="mt-0 transition-all duration-200 ease-in-out">
        <section>
          <div class="relative flex items-center min-h-screen p-0 overflow-hidden bg-center bg-cover">
            <div class="container z-1">
              <div class="flex flex-wrap -mx-3">
                <div class="flex flex-col w-full max-w-full px-3 mx-auto lg:mx-0 shrink-0 md:flex-0 md:w-7/12 lg:w-5/12 xl:w-4/12">
                  <div class="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none lg:py4 dark:bg-gray-950 rounded-2xl bg-clip-border">
                    <div class="p-6 pb-0 mb-0 mt-20">
                      <h4 class="font-bold text-yellow-400">تسجيل اشتراك جديد</h4>
                      <p class="mb-0">ادخل  اسمك و الايميل وكلمة السر</p>
                     </div>
                    <div class="flex-auto p-6">
                      <form role="form">
                        <div class="mb-4">
                          <label
                            htmlFor="Name"
                            className="block mb-2 text-yellow-400 text-sm font-medium  dark:text-white"
                          >
                            الاسم الثلاثي
                          </label>
                          <input
                            autoComplete="off"
                            onChange={(e) => setName(e.target.value)}
                            name="name"
                            type="text"
                            className={`bg-gray-50 border ${isNameValid || name.length === 0
                              ? 'border-gray-300'
                              : 'border-red-500'
                              } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            placeholder="اسمك الكامل"
                          />
                          {!isNameValid && name.length > 0 && (
                            <p className="text-red-500 text-sm mt-1">
                              الاسم يجب ان لا يقل عن 4 احرف
                            </p>
                          )}
                        </div>
                        <div class="mb-4">
                          <label
                            htmlFor="email"
                            className="block mb-2 text-sm text-yellow-400 font-medium  dark:text-white"
                          >
                            ايميلك
                          </label>
                          <input
                            autoComplete="off"
                            onChange={(e) => setEmail(e.target.value)}
                            id="email"
                            name="email"
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="name@gmail.com"
                          />
                        </div>
                        <div class="mb-4">
                          <label
                            htmlFor="Name"
                            className="block mb-2 text-sm text-yellow-400 font-medium  dark:text-white"
                          >
                            كلمة السر
                          </label>
                          <input
                            autoComplete="off"
                            onChange={(e) => setPassword(e.target.value)}
                            id="password"
                            name="password"
                            type="password"
                            className={`bg-gray-50 border ${isPasswordValid || password.length === 0
                                ? 'border-gray-300'
                                : 'border-red-500'
                              } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            placeholder="Password"
                          />

                        </div>
                        <div class="mb-4">
                          <label
                            htmlFor="ReferralCode"
                            className="block mb-2 text-sm font-medium text-yellow-400 dark:text-white"
                          >
                            كود الدعوة
                          </label>
                          <input
                            autoComplete="off"
                            onChange={(e) => setReferralCode(e.target.value)}
                            id="referralCode"
                            name="referralCode"
                            value={referralCode}
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=" Referral Code"
                          />
                        </div>
                        <div class="text-center">
                          <LoadingButton
                            onClick={handleRegister}
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                            color="warning"
                            disabled={!isNameValid || !isPasswordValid}
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                          >
                            اشترك
                          </LoadingButton>
                        </div>
                      </form>
                    </div>
                    <div class="border-black/12.5 rounded-b-2xl border-t-0 border-solid p-6 text-center pt-0 px-1 sm:px-6">
                      <p class="mx-auto mb-6 leading-normal text-sm">هل تمتلك حساب ؟
                        <NavLink to="/login" className="text-blue-500">
                          سجل دخول
                        </NavLink>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="absolute top-0 left-0 flex-col justify-center hidden w-6/12 h-full max-w-full px-3 pr-0 my-auto text-center flex-0 lg:flex">
                  <div class="relative flex flex-col justify-center h-full bg-cover px-24 m-4 overflow-hidden bg-[url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg')] rounded-xl ">
                    <span class="absolute top-0 left-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-blue-500 to-violet-500 opacity-60"></span>
                    <h4 class="z-20 mt-12 font-bold text-white">"الربح هو العملة الجديدة"</h4>
                    <p class="z-20 text-white ">
                      كلما بدا الموقع اسهل، زاد الجهد الذي بذل في بناءه.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
    // <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
    //   <div className="relative py-3 sm:max-w-xl sm:mx-auto">
    //     <div className="absolute inset-0 bg-gradient-to-r from-blue-300 to-blue-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
    //     <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
    //       <div className="max-w-md mx-auto">
    //         <div>
    //           <h1 className="text-2xl font-semibold">
    //            تسجيل اشتراك جديد
    //           </h1>
    //         </div>
    //         <div className="divide-y divide-gray-200">
    //           <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
    //             <div className="relative">
    //               <label
    //                 htmlFor="Name"
    //                 className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
    //               >
    //                الاسم الثلاثي
    //               </label>
    //               <input
    //                 autoComplete="off"
    //                 onChange={(e) => setName(e.target.value)}
    //                 name="name"
    //                 type="text"
    //                 className={`bg-gray-50 border ${
    //                   isNameValid || name.length === 0
    //                     ? 'border-gray-300'
    //                     : 'border-red-500'
    //                 } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
    //                 placeholder="اسمك الكامل"
    //               />
    //               {!isNameValid && name.length > 0 && (
    //                 <p className="text-red-500 text-sm mt-1">
    //                   الاسم يجب ان لا يقل عن 4 احرف
    //                 </p>
    //               )}
    //             </div>
    //             <div className="relative">
    //               <label
    //                 htmlFor="email"
    //                 className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
    //               >
    //                 ايميلك
    //               </label>
    //               <input
    //                 autoComplete="off"
    //                 onChange={(e) => setEmail(e.target.value)}
    //                 id="email"
    //                 name="email"
    //                 type="text"
    //                 className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    //                 placeholder="name@gmail.com"
    //               />
    //             </div>

    //             <div className="relative">
    //               <label
    //                 htmlFor="password"
    //                 className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
    //               >
    //                 كلمة السر
    //               </label>
    //               <input
    //                 autoComplete="off"
    //                 onChange={(e) => setPassword(e.target.value)}
    //                 id="password"
    //                 name="password"
    //                 type="password"
    //                 className={`bg-gray-50 border ${
    //                   isPasswordValid || password.length === 0
    //                     ? 'border-gray-300'
    //                     : 'border-red-500'
    //                 } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
    //                 placeholder="Password"
    //               />
    //               {!isPasswordValid && password.length > 0 && (
    //                 <p className="text-red-500 text-sm mt-1">
    //                  كلمة السر يجب ان لاتقل عن 8 احرف
    //                 </p>
    //               )}
    //             </div>
    //             <div className="relative">
    //               <label
    //                 htmlFor="ReferralCode"
    //                 className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
    //               >
    //                 كود الدعوة
    //               </label>
    //               <input
    //                 autoComplete="off"
    //                 onChange={(e) => setReferralCode(e.target.value)}
    //                 id="referralCode"
    //                 name="referralCode"
    //                 value={referralCode}
    //                 type="text"
    //                 className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    //                 placeholder=" Referral Code"
    //               />
    //             </div>

    //             <div className="relative">
    //               <LoadingButton
    //                 onClick={handleRegister}
    //                 loading={loading} 
    //                 loadingPosition="end"
    //                 variant="contained"
    //                 color="warning"
    //                 disabled={!isNameValid || !isPasswordValid}
    //                 className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
    //               >
    //                اشترك
    //               </LoadingButton>
    //             </div>
    //             <p className="text-sm text-gray-500">
    //              هل تمتلك حساب?{' '}
    //               <NavLink to="/login" className="text-blue-500">
    //                 دخول
    //               </NavLink>
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default Register;