import React, { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { HomeContext } from '../../contexts/HomeContext';
import LoadingCircle from '../../components/LoadingCircle';
import MultiPackages from '../../components/MultiPackages';

function Packages() {
  const { getPackages, packages } = useContext(HomeContext);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getPackages().then(response => {
      setLoading(false);
      console.log(response);
    })
      .catch(error => {
        if (error.response?.data) {
          toast.error("An error occurred retrieving data" + error.response?.data?.message);
          console.log(error.response.data.message);
        }
        else {
          toast.error("An unknown error has occurred");
          console.log(error);
        }


      });
    console.log(packages);
  }, []);

  if (loading) {
    return <LoadingCircle />;
  }

  return (
    <div class="w-full mb-20 px-6 py-6 mx-auto">
      {/* <!-- content --> */} 
      <MultiPackages packages={packages} /> 
    </div>
  )
}

export default Packages