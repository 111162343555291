import React from 'react';
import { useParams } from 'react-router-dom';

function ErrorPage() {
  const { errorCode } = useParams();
  let errorMessage;
  let errorTitle;
  let imageUrl;

  if (errorCode === '500') {
    errorTitle = 'Internal Server Error';
    errorMessage = 'Sorry, something went wrong on our end. Please try again later.';
    imageUrl = '/assets/images/500.jpg'; // استبدل برابط الصورة الخاصة بك
  } else if (errorCode === '404') {
    errorTitle = 'Page Not Found';
    errorMessage = 'Oops! The page you are looking for does not exist.';
    imageUrl = '/assets/images/404.jpg'; // استبدل برابط الصورة الخاصة بك
  } else {
    errorTitle = 'Error';
    errorMessage = 'An error occurred.';
    imageUrl = '/assets/images/500.jpg'; // استبدل برابط الصورة الخاصة بك
  }

  return (
     
    
      <img src={imageUrl} alt="Error" className="max-w-full h-auto" />
    
  );
}

export default ErrorPage;