import React, { useContext, useState } from 'react';
import toast from "react-hot-toast";
import { HomeContext } from '../../contexts/HomeContext';
import { LoadingButton } from "@mui/lab";
import { BsSendFill } from 'react-icons/bs';
import QRCode from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from "react-icons/fa6";
import LoadingCircle from '../../components/LoadingCircle';

const MethodOnline = ({ method }) => {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(''); 
  const [note, setNote] = useState('');
  const { requestDeposit } = useContext(HomeContext);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    const amountValue = parseFloat(amount);
    if ((method.main_amount > 0 && amountValue < method.main_amount) || (method.max_amount > 0 && amountValue > method.max_amount)) {
      toast.error(`المبلغ يجب أن يكون بين ${method.main_amount} و ${method.max_amount}`);
      setLoading(false);
      return;
    }
  
    const formData = new FormData();
    formData.append('deposit_method_id', method.id);
    formData.append('amount', amountValue);
    formData.append('note', note);
  
    const res = await requestDeposit(formData);
    setLoading(false);
  
    if (res.status) {
      toast.success(res.message);
      if (res.payLink) {
        window.location.href = res.payLink;
      }
    } else {
      toast.error(res.message);
    }
  };
  

  const handleCopy = () => {
    toast.success('تم النسخ');
  };

  if (loading || method.name === null) {
    return <LoadingCircle />;
  }

  return (
    <div className="flex flex-wrap mt-6 mb-20 mx-auto max-w-4xl px-4 lg:px-0" style={{ width: "-webkit-fill-available" }}>
      
      <div className="w-full lg:w-7/12">
           <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 dark:text-gray-300 font-semibold">المبلغ:</label>
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="w-full mt-2 p-2 border rounded-md dark:bg-gray-700 dark:text-white"
              />
            </div>
             
            <div className="mb-4">
              <label className="block text-gray-700 dark:text-gray-300 font-semibold">ملاحظة (اختياري)</label>
              <textarea
                value={note}
                onChange={(e) => setNote(e.target.value)}
                className=" "
              />
            </div>
            <div className="flex justify-center">
              <LoadingButton
                onClick={handleSubmit}
                loading={loading}
                loadingPosition="end"
                variant="contained"
                color="warning"
                className="bg-green-500 text-white font-bold rounded-lg px-6 py-2 hover:bg-green-600"
              >
                اكمال الايداع
              </LoadingButton>
            </div>
          </form>
        </div>
      </div>
   );
};

export default MethodOnline;
