import React, { useCallback, useContext, useState } from "react";
import { toast } from "react-hot-toast";
import { HomeContext } from "../../contexts/HomeContext";

export const Password = () => {
  const { updatePassword } = useContext(HomeContext);
  const [values, setValues] = useState({
    old_password: "",
    new_password: "",
    new_password_confirmation: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = useCallback((event) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const res = await updatePassword(values);
      setLoading(false);

      if (res.status) {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <div className="card"> 
        <div className="card-content">
          <div className="grid grid-cols-1 gap-4">
            <div>
              <label className="block mb-1 text-black">كلمة السر الحالية</label>
              <input
                type="password"
                className="input"
                name="old_password"
                onChange={handleChange}
                value={values.old_password}
                required
              />
            </div>
            <div>
              <label className="block mb-1 text-black">كلمة السر الجديدة</label>
              <input
                type="password"
                className="input"
                name="new_password"
                onChange={handleChange}
                value={values.new_password}
                required
              />
            </div>
            <div>
              <label className="block mb-1 text-black">تأكيد كلمة السر</label>
              <input
                type="password"
                className="input"
                name="new_password_confirmation"
                onChange={handleChange}
                value={values.new_password_confirmation}
                required
              />
            </div>
          </div>
        </div>
        <div className="card-actions justify-end">
          <button
            type="submit"
            className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            disabled={loading}
          >
            {loading ? "يحفظ..." : "حفظ"}
          </button>
        </div>
      </div>
    </form>
  );
};