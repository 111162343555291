import React from 'react';

function AboutUs(props) {
  return (
    <div class="w-full px-6 py-6 mx-auto ">
      <div class="flex flex-wrap -mx-3 h-screen" >
        <div class="flex-none w-full max-w-full px-3">
          <div class="relative flex p-6  flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
            <div class="p-6 pb-0 mb-0 border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
              <h1 className="text-2xl font-bold mb-4">من نحن</h1>
            </div>

            <p className="mb-4">
            Digital Money :
              <br />
              مرحبًا بكم في [Digitalmoney]، منصتكم الرائدة للاستثمار في العملات الرقمية. نحن هنا لنقدم لكم تجربة استثمارية فريدة ومبتكرة في عالم العملات الرقمية المتغير بسرعة.


            </p>
            <h2 className='text-xl font-bold'>رؤيتنا:
            </h2>
            <p className="mb-4">
            نسعى لأن نكون الوجهة الأولى للمستثمرين الذين يرغبون في استكشاف الفرص الواعدة في مجال العملات الرقمية. نؤمن بأن التكنولوجيا المالية يمكن أن تحدث تغييرًا جذريًا في الطريقة التي نتعامل بها مع المال، ونسعى لتقديم أدوات وموارد تسهل هذا التحول.
             </p>
            <h2 className='text-xl font-bold'>مهمتنا:
            </h2>
            <p className="mb-4">
            تهدف [digitalmoney] إلى تمكين المستثمرين من جميع المستويات من الوصول إلى المعلومات والموارد اللازمة لاتخاذ قرارات استثمارية مدروسة. نقدم مجموعة من الخدمات تتضمن:
-•الاستثمار السهل: واجهة مستخدم بسيطة وسهلة الاستخدام تتيح لك أستثمار العملات الرقمية بسهولة.
- الأمان: نضع أمان أموالك في مقدمة أولوياتنا، مع تقنيات متقدمة لحماية بياناتك ومعاملاتك.

             </p>
            <h2 className='text-xl font-bold'>فريقنا:
            </h2>
            <p className="mb-4">
            يضم فريقنا مجموعة من الخبراء في مجالات التكنولوجيا المالية، وتحليل السوق، وأمان المعلومات. نحن متفانون في تقديم الدعم والمشورة لمساعدتك في نجاح استثماراتك.
            </p>
            <h2 className='text-xl font-bold'>انضم إلينا:
            </h2>
            <p className="mb-4">
            سواء كنت مبتدئًا أو مستثمرًا محترفًا، نحن هنا لدعمك في كل خطوة على الطريق. انضم إلى [digitalmoney] اليوم وابدأ رحلتك نحو تحقيق أهدافك المالية في عالم العملات الرقمية.

             </p>
          
            <p className="mb-4">
                لأي استفسارات أو مزيد من المعلومات، لا تتردد في الاتصال بنا. نحن هنا لمساعدتك!
             </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;