import React from 'react';

function ConnectUs(props) {
    return (

        <div class="w-full px-6 py-6 mx-auto ">
            <div class="flex flex-wrap -mx-3 h-screen" >
                <div class="flex-none w-full max-w-full px-3">
                    <div class="relative flex p-6  flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                        <div class="p-6 pb-0 mb-0 border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
                            <h1 className="text-2xl font-bold mb-4">اتصل بنا</h1>
                        </div>

                        <p className="mb-4">
                            إليك بعض جهات التواصل التي يمكنك الإتصال بها مع فريق دعم العملاء بشكل مباشر :

                        </p>
                        <p className="mb-4">
                            1. رقم الهاتف عبر واتساب : <br />
                            00441946398068

                        </p>
                        <p className="mb-4">
                            2. البريد الإلكتروني: <br />
                           عنوان البريد الإلكتروني الرسمي للدعم الفني : support@digitalmoney2024.com

                        </p>
                        <p className="mb-4">
                            3.ساعات العمل: <br />
                          مواعيد أوقات العمل: من الأحد إلى السبت
                            من 9 صباحًا إلى 9 مساءً

                        </p>
                        {/* المزيد من المحتوى */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConnectUs;