// TermsOfUse.js
import React from 'react';

function TermsOfUse() {
  return (
    <div class="w-full px-6 py-6 mx-auto">

      <div class="relative  p-5 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
        <h1 className="text-2xl font-bold mb-4">شروط الاستخدام</h1>
        <p>
          أهلاً بك في <strong>digitalmoney2024.com</strong>! هذه الشروط تحدد القواعد والأسس التي يمكنك من خلالها استخدام موقعنا وخدماتنا.
        </p>

        <h2 className="text-xl font-bold mt-4">استخدام الموقع</h2>
        <p>
          باستخدام موقعنا، فإنك توافق على الامتثال لجميع القوانين المعمول بها وأنك لن تستخدم الموقع لأي أنشطة غير قانونية أو مضرة.
        </p>

        <h2 className="text-xl font-bold mt-4">الاستثمار في العملات المشفرة</h2>
        <p>
          خدماتنا تعتمد على الاستثمار في العملات المشفرة، ونحن لا نقدم أي ضمانات لتحقيق الأرباح. يجب عليك القيام ببحث مستقل وطلب استشارة مالية عند الضرورة.
        </p>

        <h2 className="text-xl font-bold mt-4">تحديد المسؤولية</h2>
        <p>
          <strong>digitalmoney2024.com</strong> لن تكون مسؤولة عن أي خسائر أو أضرار تنتج عن استخدامك للموقع أو خدماتنا الاستثمارية. أنت المسؤول الوحيد عن قرارات الاستثمار الخاصة بك.
        </p>

        <h2 className="text-xl font-bold mt-4">التعديلات على الشروط</h2>
        <p>
          نحتفظ بالحق في تعديل هذه الشروط في أي وقت، وسيتم نشر التعديلات هنا. إن استمرارك في استخدام الموقع يعني موافقتك على الشروط المعدلة.
        </p>
      </div>
    </div>
  );
}

export default TermsOfUse;
