import React, { useContext, useEffect, useState } from "react";
import { HomeContext } from "../../contexts/HomeContext";
import toast from "react-hot-toast";
import LoadingCircle from "../../components/LoadingCircle";
import TopCard from "../../components/cards/TopCard";
import { NavLink } from "react-router-dom";

function MyWallet() {
  const { getWallet, myWallet } = useContext(HomeContext);
  const [loading, setLoading] = useState(true);
  const [filterType, setFilterType] = useState(""); // حالة محلية لتتبع النوع المحدد

  useEffect(() => {
    getWallet()
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data) {
          toast.error(
            "An error occurred retrieving data: " + error.response?.data.message
          );
          console.log(error.response.data.message);
        } else {
          toast.error("An unknown error has occurred");
          console.log(error);
        }
      });
  }, []);

  const handleFilter = (type) => {
    setFilterType(type); // تحديث النوع المحدد عند النقر على الزر
  };

  if (loading) {
    return <LoadingCircle />;
  }

  // تطبيق التصفية عند عرض الصفوف في الجدول
  const filteredTransactions = myWallet.history_balance.filter(
    (transaction) => {
      if (filterType === "") {
        return true; // عرض جميع الصفوف إذا لم يتم تحديد نوع التصفية
      }
      return transaction.transaction_type === filterType;
    }
  );

  return (
    <>
      <div className="w-full px-6 py-6 mx-auto">
        <div className="flex flex-wrap -mx-3">
          <TopCard title="رصيدك" value={`${myWallet.balance}$`} icon="ni leading-none ni-money-coins text-lg relative top-3.5 text-white" />
          <TopCard title="ارباحك" value={`${myWallet.profits}$`} icon="ni leading-none ni-world text-lg relative top-3.5 text-white" />
          <TopCard title="مسحوباتك" value={`${myWallet.withdraws}$`} icon="ni leading-none ni-paper-diploma text-lg relative top-3.5 text-white" />
        </div>
      </div>

      <div className="w-full px-6 py-6 mx-auto">
        {/* زري التصفية */}
        

        <div className="flex flex-wrap -mx-3">
          <div className="flex-none w-full max-w-full px-3">
            <div className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
              <div className="p-6 pb-0 mb-0 border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
                <h6 className="dark:text-white">سجل العمليات على الرصيد</h6>
                <div className="mb-4 flex items-center gap-4">
          <span className="text-lg font-semibold p-5">فلترة ب :</span>

          <button
            className={`px-4 py-2 rounded-lg transition-all ${
              filterType === "1"
                ? "bg-green-500 text-white shadow-lg hover:bg-green-600"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
            onClick={() => handleFilter("1")}
          >
            الوارد
          </button>

          <button
            className={`px-4 py-2 rounded-lg transition-all ${
              filterType === "0"
                ? "bg-red-500 text-white shadow-lg hover:bg-red-600"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
            onClick={() => handleFilter("0")}
          >
            الصادر
          </button>
        </div>
              </div>

              <div className="flex-auto px-0 pt-0 pb-2">
                <div className="p-5 overflow-x-auto">
                  {/* عرض جدول لأجهزة الكمبيوتر */}
                  <div className="hidden md:block">
                    <table className="items-center w-full mb-0 align-top border-collapse dark:border-white/40 text-slate-500">
                      <thead className="align-bottom">
                        <tr>
                          <th className="py-3 font-bold text-right uppercase align-middle bg-transparent border-b border-collapse shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">id</th>
                          <th className="px-6 py-3 pl-2 font-bold text-right uppercase align-middle bg-transparent border-b border-collapse shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">البيان</th>
                          <th className="px-6 py-3 font-bold text-right uppercase align-middle bg-transparent border-b border-collapse shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">الباقة</th>
                          <th className="px-6 py-3 font-bold text-right uppercase align-middle bg-transparent border-b border-collapse shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">المبلغ</th>
                          <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-collapse shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">الرصيد قبل</th>
                          <th className="px-6 py-3 font-bold text-right uppercase align-middle bg-transparent border-b border-collapse shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">الرصيد بعد</th>
                          <th className="px-6 py-3 font-bold text-right uppercase align-middle bg-transparent border-b border-collapse shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">التاريخ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredTransactions.map((transaction) => (
                          <tr
                            key={transaction.id}
                            className={`${
                              transaction.transaction_type === "1"
                                ? "bg-green-300"
                                : transaction.transaction_type === "0"
                                ? "bg-red-200"
                                : ""
                            }`}
                          >
                            <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                              <p className="mb-0 text-xs font-semibold leading-tight dark:text-white dark:opacity-80">
                                {transaction.id}
                              </p>
                            </td>
                            <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                              <div className="flex px-2 py-1">
                                <div className="flex flex-col justify-center">
                                  <h6 className="mb-0 text-sm leading-normal dark:text-white">{transaction.reason}</h6>
                                  <p className="mb-0 text-xs leading-tight dark:text-white dark:opacity-80 text-slate-400">{transaction.transaction_type_lang}</p>
                                </div>
                              </div>
                            </td>
                            <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                              <p className="mb-0 text-xs font-semibold leading-tight dark:text-white dark:opacity-80">
                                {transaction.package_id}
                              </p>
                            </td>
                            <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                              <p className="mb-0 text-xs font-semibold leading-tight dark:text-white dark:opacity-80">
                                {transaction.amount}
                              </p>
                            </td>
                            <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                              <p className="mb-0 text-xs font-semibold leading-tight dark:text-white dark:opacity-80">
                                {transaction.balance_before}
                              </p>
                            </td>
                            <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                              <p className="mb-0 text-xs font-semibold leading-tight dark:text-white dark:opacity-80">
                                {transaction.balance_after}
                              </p>
                            </td>
                            <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                              <h6 className="mb-0 text-sm leading-normal dark:text-white">{transaction.created_at_diffForHumans}</h6>
                              <p className="mb-0 text-xs leading-tight dark:text-white dark:opacity-80 text-slate-400">
                                {transaction.created_at}
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  
                  {/* عرض البيانات بشكل بطاقات للموبايل */}
                  <div className="block md:hidden">
                    {filteredTransactions.map((transaction) => (
                      <div
                        key={transaction.id}
                        className={`mb-4 p-4 rounded-lg shadow-lg ${
                          transaction.transaction_type === "1"
                            ? "bg-green-200"
                            : transaction.transaction_type === "0"
                            ? "bg-red-200"
                            : ""
                        }`}
                      >
                        <p className="text-sm font-semibold">{transaction.reason}</p>
                        <p className="text-xs text-slate-500">الباقة: {transaction.package_id}</p>
                        <p className="text-xs text-slate-500">المبلغ: {transaction.amount}</p>
                        <p className="text-xs text-slate-500">الرصيد قبل: {transaction.balance_before}</p>
                        <p className="text-xs text-slate-500">الرصيد بعد: {transaction.balance_after}</p>
                        <p className="text-xs text-slate-500">التاريخ: {transaction.created_at_diffForHumans}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyWallet;
