import React, { useContext, useState } from 'react';
import toast from "react-hot-toast";
import { HomeContext } from '../../contexts/HomeContext';
import { LoadingButton } from "@mui/lab";
import { BsSendFill } from 'react-icons/bs';
import QRCode from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from "react-icons/fa6";
import LoadingCircle from '../../components/LoadingCircle';

const MethodOffline = ({ method }) => {
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState('');
    const [note, setNote] = useState('');
    const { requestDeposit } = useContext(HomeContext);
    const [transactionNumber, setTransactionNumber] = useState('');
    const [receiptImage, setReceiptImage] = useState(null); // حفظ الصورة

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const amountValue = parseFloat(amount);
        if ((method.main_amount > 0 && amountValue < method.main_amount) || (method.max_amount > 0 && amountValue > method.max_amount)) {
            toast.error(`المبلغ يجب أن يكون بين ${method.main_amount} و ${method.max_amount}`);
            setLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append('deposit_method_id', method.id);
        formData.append('amount', amountValue);
        formData.append('transactionNumber', transactionNumber);
        formData.append('note', note);

        if (receiptImage) {
            formData.append('receipt_image', receiptImage); // إضافة الصورة إلى الفورم
        }

        const res = await requestDeposit(formData);
        setLoading(false);

        if (res.status) {
            toast.success(res.message);
            if (res.payLink) {
                window.location.href = res.payLink;
            }
        } else {
            toast.error(res.message);
        }
    };

    const handleCopy = () => {
        toast.success('تم النسخ');
    };

    if (loading || method.name === null) {
        return <LoadingCircle />;
    }

    return (
        <div className="flex flex-wrap mt-6 mb-20 mx-auto max-w-4xl px-4 lg:px-0" style={{ width: "-webkit-fill-available" }}>
            <div className="border-black/12.5 shadow-xl relative z-20 dark:bg-slate-850 dark:shadow-dark-xl flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
                <h1 className="mt-6 mb-4 mx-auto dark:text-white text-2xl"> {method.name}</h1>
                <h6 className="mt-6 mb-0 mr-2 dark:text-white">{method.message}</h6>
                <div className="flex-auto p-4">
                    <div className="flex justify-center py-4 pr-1 bg-gradient-to-tl dark:bg-gradient-to-tl dark:from-slate-750 dark:to-gray-850 rounded-xl">
                        <div>
                            {method.account && <QRCode value={method.account} renderAs="canvas" />}
                        </div>
                    </div>
                    <div className="flex flex-col content-center items-center py-4 pr-1 bg-gradient-to-tl dark:bg-gradient-to-tl dark:from-slate-750 dark:to-gray-850 rounded-xl">
                        <div>{method.account}</div>
                        <div>
                            <CopyToClipboard text={method.account} onCopy={handleCopy}>
                                <button className="block ml-2">
                                    <FaCopy className="inline-block align-middle" size={18} />
                                </button>
                            </CopyToClipboard>
                        </div>
                    </div>

                    <p className="mr-2 text-sm leading-normal">{method.note}</p>
                </div>
            </div>

            <div className="w-full lg:w-7/12">
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-bold">المبلغ:</label>
                        <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} className="form-input mt-1 block w-full" />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-bold"> رقم الحوالة او رقم المرسل   :</label>
                        <input type="text" value={transactionNumber} onChange={(e) => setTransactionNumber(e.target.value)} className="form-input mt-1 block w-full" />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-bold">ملاحظة:</label>
                        <textarea value={note} onChange={(e) => setNote(e.target.value)} className="form-textarea mt-1 block w-full" />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 font-bold">إضافة صورة الإشعار:</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => setReceiptImage(e.target.files[0])} // تحديث حالة الصورة
                            className="form-input mt-1 block w-full"
                        />
                    </div>

                    <div className="relative">
                        <LoadingButton
                            onClick={handleSubmit}
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                            color="warning"
                            className="bg-green-500 text-white rounded-md px-4 py-2"
                        >
                            تأكيد الايداع
                        </LoadingButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default MethodOffline;
