export default function TopCard ({ title, value, icon }) {
    return (
      <div className="w-1/2 px-3 mb-6 sm:w-1/4 sm:flex-none xl:mb-0 xl:w-1/4">
        <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
          <div className="flex-auto p-4">
            <div className="flex flex-row -mx-3">
              <div className="flex-none w-2/3 max-w-full px-3">
                <div>
                  <p className="mb-0 font-sans text-sm font-semibold leading-normal">{title}</p>
                  <h5 className="mb-0 font-bold dark:text-white">
                    {value}
                  </h5>
                </div>
              </div>
              <div className="px-3 text-left basis-1/3">
                <div className="inline-block w-12 h-12 text-center rounded-lg bg-yellow-400 ">
                  <i className={icon} aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }