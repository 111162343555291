import React, { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { HomeContext } from "../contexts/HomeContext";
import { AuthContext } from "../contexts/AuthContext";

import { useAuthContext } from "../contexts/AuthContext";
import { Link } from "react-router-dom";

function OneDepositMethod({ depo }) {
  const { user } = useContext(AuthContext);

  const { userLoggedIn } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const base_url = window.location.hostname === 'localhost' ? 'http://127.0.0.1:8000/images/' : 'https://app.digitalmoney2024.com/images/';

  return (
    <Link to={'/deposit-methods/'+depo.id} ><div className="bg-white rounded-lg shadow-md p-4 " key={depo.id} >
      <img src={base_url+depo.image} /> 
      {depo.name} 
    </div></Link>
     

  );
}

export default OneDepositMethod;