import React, { useContext, useState } from "react";
import { HomeContext } from "../contexts/HomeContext";
import toast from "react-hot-toast";
import { BsSendFill } from "react-icons/bs";
import { LoadingButton } from "@mui/lab";

function MultiPackages({ packages }) {
  
  const base_url = window.location.hostname === 'localhost' ? 'http://127.0.0.1:8000/images/' : 'https://app.digitalmoney2024.com/images/';
  const { requestSubscrip } = useContext(HomeContext);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event, packageId) => {
    event.preventDefault();
    setLoading(true);
  
    const formData = new FormData();
    formData.append('package_id', packageId);
  
    try {
      const res = await requestSubscrip(formData);
      setLoading(false);
  
      if (res.status) {
        toast.success(res.message);
      } else {
        toast.error(res.message || "An unexpected error occurred.");
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        // Handle specific errors, such as insufficient balance
        if (error.response.status === 400 && error.response.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      } else {
        toast.error("An unknown error occurred.");
      }
    }
  };
  

  try {
    if (packages.length === 0) {
      return (
        <div>
          <p>is Empty</p>
        </div>
      );
    }
  } catch (error) { }

  return (
<div class="grid grid-cols-1 md:grid-cols-3 gap-4">
      {packages?.map((depo) => (
        <div class=" " key={depo.id} >
          <div class="relative flex flex-col min-w-0 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
            <div class="p-4 mx-6 mb-0 text-center border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
              <div class="  text-center bg-center shadow-sm   bg-yellow-400 rounded-xl">
                <img
                  src={base_url + depo.image}
                  alt={depo.name}
                  className="w-full h-32 object-cover mb-4"
                />
              </div>
            </div>
            <div class="flex-auto p-4 pt-0 text-center">
              <h6 class="mb-0 text-center dark:text-white">{depo.name_ar}</h6>
              <p><span class="text-xs leading-tight dark:text-white dark:opacity-80">{depo.price}$</span></p>
              <p><span class="text-xs leading-tight dark:text-white dark:opacity-80">{depo.description}</span></p>
              <hr class="h-px my-4 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent" />
              <h5 class="mb-0 dark:text-white">
                <LoadingButton
                  onClick={(event) => handleSubmit(event, depo.id)}
                  loading={loading}
                  loadingPosition="end"
                  variant="contained"
                  color="warning"
                  classes="bg-yellow-400  text-white rounded-md px-4 py-2"
                >
                  اشترك الان
                </LoadingButton></h5>
            </div>
          </div>
        </div>

      ))}
    </div>
  );
}

export default MultiPackages;