import React, { useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import Notifications from "./menu/Notifications";
import { HomeContext } from "../contexts/HomeContext";
import { FaSun, FaMoon } from 'react-icons/fa';
import DarkModeToggle from "./DarkModeToggle";


function TopHeader({
  isSubHeaderOpen, toggleSidebar
}) {
  const { markAllNotificationsAsRead, myHome } = useContext(HomeContext);
  const [isDarkMode, setIsDarkMode] = useState(false);
  var userLoggedIn = Cookies.get("userLoggedIn");
  const [isNotificationsOpen, setNotificationsOpen] = useState(false);

  const handleNotificationsClick = () => {
    setNotificationsOpen(!isNotificationsOpen);
  };

  const handleMarkAllAsRead = () => {
    markAllNotificationsAsRead();
  };

  useEffect(() => {
    const rootHtml = document.querySelector("html");
    if (isDarkMode) {
      rootHtml.classList.add("dark");
    } else {
      rootHtml.classList.remove("dark");
    }
  }, [isDarkMode]);


  if (!myHome) return "";
  return (
    <nav class="fixed h-16 top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-slate-850 dark:border-gray-700">
      <div class="px-3 py-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between mt-2 grow sm:mt-0 md:mr-0 lg:flex lg:basis-auto">
          {/* أيقونة القائمة الجانبية (Sidebar) - تظهر على اليمين في الشاشات الصغيرة وتختفي في الشاشات الكبيرة */}
          <ul className="flex flex-row-reverse items-center justify-end w-full sm:w-auto pr-4   xl:hidden">
           <li className="flex items-center">
              <img src="logo/logo_name.png" class="inline h-full max-w-full transition-all duration-200 dark:hidden ease-nav-brand max-h-10" alt="main_logo" />
              <img src="logo/logo_name.png" class="hidden h-full max-w-full transition-all duration-200 dark:inline ease-nav-brand max-h-10" alt="main_logo" />
                {/* اسم الموقع */}
                <span className=" font-semibold text-gray-900 dark:text-white">
                  Digital Money
                </span>
               </li>
            <li className="flex items-center ml-4">
              <a href="#" onClick={toggleSidebar} className="block p-0 text-2xl text-yellow-400 transition-all ease-nav-brand" sidenav-trigger>
                <div className="w-4.5 overflow-hidden">
                  <i className="ease mb-0.75 relative block h-1 rounded-sm bg-yellow-400 transition-all"></i>
                  <i className="ease mb-0.75 relative block h-1 rounded-sm bg-yellow-400 transition-all"></i>
                  <i className="ease relative block h-1 rounded-sm bg-yellow-400 transition-all"></i>
                </div>
              </a>
            </li>
          </ul>

          {/* الأيقونات الأخرى - تظهر دائمًا على اليسار */}
          <ul className="flex items-center ml-0 mr-auto list-none">
            {/* إشعارات */}
            <li className="relative flex items-center pl-2">
              <p className={`relative -top-12 transform-dropdown-show bg-red-500 rounded-full w-5 h-5 flex items-center justify-center text-white text-xs ${myHome.countUnreadNotifi > 0 ? 'block' : 'hidden'}`}>
                {myHome.countUnreadNotifi > 9 ? '+9' : myHome.countUnreadNotifi}
              </p>
              <a href="#" onClick={handleNotificationsClick} className="block p-0 text-2xl text-yellow-400 transition-all ease-nav-brand" aria-expanded={isNotificationsOpen}>
                <i className="cursor-pointer fa fa-bell" aria-hidden="true"></i>
              </a>

              {/* قائمة الإشعارات */}
              <ul
                dropdown-menu
                className={`text-sm mt-8 dark:bg-slate-850 dark:shadow-dark-xl before:font-awesome before:leading-default before:duration-350 before:ease lg:shadow-3xl duration-250 min-w-44 absolute left-0 top-0 z-50 origin-top list-none rounded-lg border-0 border-solid border-transparent bg-white bg-clip-padding px-2 py-4 text-left text-slate-500 transition-all before:absolute before:right-auto before:top-0 before:left-2 before:z-50 before:inline-block before:font-normal before:text-white before:antialiased before:transition-all before:content-['\f0d8'] sm:-mr-6 before:sm:left-3 lg:absolute lg:cursor-pointer ${isNotificationsOpen ? '' : 'hidden'}`}
                style={{
                  maxHeight: "25rem",
                  overflow: "auto",
                }}
              >
                <div className="flex justify-between px-2 py-1">
                  <button
                    className="text-sm text-blue-500 focus:outline-none"
                    onClick={handleMarkAllAsRead}
                  >
                    تحديد الكل كمقروء
                  </button>
                </div>
                <hr />
                <Notifications />
              </ul>
            </li>

            {/* تبديل الوضع المظلم */}
            <li>
              <DarkModeToggle isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
            </li>
          </ul>
        </div>

      </div>
    </nav>

    // <header className="shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)] z-10 h-16 dark:bg-gray-800 " style={{ minWidth: "300px" }}  dir="rtl">
    //   <div className="container flex items-center justify-between h-full px-6 mx-auto text-purple-600 dark:text-purple-300">

    //     {/* header desktop */}
    //     <div className="items-center   ml-4 sm:flex ">
    //       {/* Settings button  */}
    //       {userLoggedIn ?
    //        <ul className="flex flex-row items-center flex-shrink-0 space-x-6 gap-x-4">

    //           <li className=" ">
    //           <img src="/logo.png" style={{ width:"1.5rem" }} /> 
    //           </li>
    //           <li className="   ">
    //             <Notifications />
    //           </li>

    //         </ul>
    //         : null}
    //     </div>
    //     {/* header mobile */}
    //     <div >

    //     </div>
    //     {/* Mobile sub header  */}
    //     {isSubHeaderOpen ? (
    //       <div className="absolute flex items-center justify-between p-2 bg-white rounded-md shadow-lg sm:hidden top-16 left-5 right-5"></div>
    //     ) : (
    //       <></>
    //     )}
    //   </div>
    // </header>
  );
}

export default TopHeader;
