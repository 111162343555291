import React, { useState } from "react";  
import OneDepositMethod from "./OneDepositMethod";
import OneWithdrawMethod from "./OneWithdrawMethod";

function MultWithdrawMethods({withdrawMethods}) { 
  try {
    if (withdrawMethods.length === 0) {
      return <div>
        <p> is Empty </p>
      </div>;
    }
  } catch (error) { }
 
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-7"> 
      {withdrawMethods?.map((depo) => (
        <div className="w-full  "  >
        <OneWithdrawMethod  depo={depo} />
       
        </div>
      ))} 
    </div>
  );
}


export default MultWithdrawMethods;