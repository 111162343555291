import React, { useContext, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { NavLink, Outlet } from "react-router-dom";
import { HomeContext } from "../contexts/HomeContext";
import BottomBar from "../components/BottomBar";
import Menu from "../components/menu/Menu";
import TopHeader from "../components/TopHeader";
import { useAuthContext } from "../contexts/AuthContext";
 
const Layout = () => {
  const { getMyHome,  } = useContext(HomeContext);
  const [loading, setLoading] = useState(true);
  const { user } = useAuthContext();
  
  // Theme state
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentSidebarTab, setCurrentSidebarTab] = useState("sectionsTab");
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    getMyHome()
      .then(() => setLoading(false))
      .catch((error) => {
        if (error.response?.data?.message) {
          toast.error("An error occurred retrieving data: " + error.response.data.message);
          console.log(error.response.data.message);
        } else {
          toast.error("An unknown error has occurred");
          console.log(error);
        }
      });
  }, []);

    // Check email verification
    useEffect(() => {
      console.log('user is : ',user)
       if (user && !user.email_verified_at) {
 
          window.location.href = "/verify-email"
        // Or show a warning banner
      }
    }, [user]);

  const toggleSidebar = (tab) => {
    setIsSidebarOpen(!isSidebarOpen);
    setCurrentSidebarTab(tab); // Update the current tab
  };

  const closeTabByClick = () => {
    if (isMobile) setIsSidebarOpen(false);
    console.log("closeTabByClick");
  };

  return (
    <>
      {/* Mobile bottom bar */}
      <BottomBar toggleSidebar={toggleSidebar} currentSidebarTab={currentSidebarTab} />
     
      {/* Left mini bar */}
      <Menu closeTabByClick={closeTabByClick} isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

      <TopHeader loading={loading} toggleSidebar={toggleSidebar} setLoading={setLoading} />

      <main className={`mt-16 px-6 pt-6 xl:mr-68  ` }>
        <Outlet />
      </main>

      {isSidebarOpen && (
        <div
          className="sm:hidden"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            overflow: "auto",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: '998'
          }}
          onClick={closeTabByClick}
        ></div>
      )}

      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
};

export default Layout;
