import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // قم بتنفيذ العمليات اللازمة لتسجيل الخروج
    Cookies.remove('userLoggedIn');
    Cookies.remove('token');
    Cookies.remove('user');

    // قم بتحويل المستخدم إلى الصفحة الرئيسية
    navigate('/');
    
  }, []);

  return <div>Logging out...</div>;
};

export default Logout;