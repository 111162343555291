import React from 'react'

export default function TransferAllWorld() {
    return (
        <div class="w-full px-6 py-6 mx-auto ">
            <div class="flex flex-wrap -mx-3 h-screen" >
                <div class="flex-none w-full max-w-full p-3">
                    <div class="relative flex flex-col min-h-screen min-w-0  p-10  mb-6 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">

                        <div className='text-2xl'> قريبا جدا ....</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
