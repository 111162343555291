import React, { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { HomeContext } from '../../contexts/HomeContext';
import MultiDepositMethods from '../../components/MultiDepositMethods';

function DepositMethod() {
    const { getDepositMethods, depositMehods, setDepositMehods } = useContext(HomeContext); // استدعاء طريقة الجلب والحالة المخزنة
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!depositMehods) {  // تحقق ما إذا كانت طرق الإيداع مخزنة
            setLoading(true);
            getDepositMethods()
                .then(response => {
                    setDepositMehods(response); // تخزين البيانات في الـ context
                })
                .catch(error => {
                    if (error.response?.data) {
                        toast.error("An error occurred retrieving data: " + error.response?.data?.message);
                        console.log(error.response.data.message);
                    } else {
                        toast.error("An unknown error has occurred");
                        console.log(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [getDepositMethods, depositMehods, setDepositMehods]);

    if (loading) {
        return <p>Loading...</p>; // عرض رسالة أثناء التحميل
    }

    return (
        <div class="w-full px-6 py-6 mx-auto ">
            <div class="flex flex-wrap -mx-3 h-screen" >
                <div class="flex-none w-full max-w-full px-3">
                    <div class="relative   ">

                        <MultiDepositMethods depositMethods={depositMehods} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DepositMethod;
