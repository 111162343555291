import React from 'react'
import { BiTrendingUp } from 'react-icons/bi'
import { BiTrendingDown } from "react-icons/bi";

export default function Trending({item}) {
    return (
        <div class="relative mb-4 mt-0 after:clear-both after:table after:content-['']">
               {item.transaction_type == 1 ? 
               <span class="w-6.5 h-6.5 text-base absolute right-4 z-10 inline-flex translate-x-1/2 items-center justify-center rounded-full  text-center font-semibold bg-green-700">
               <BiTrendingUp class="relative z-10   leading-pro bg-gradient-to-tl from-white  bg-clip-text text-white " />
               </span> :
              <span class="w-6.5 h-6.5 text-base absolute right-4 z-10 inline-flex translate-x-1/2 items-center justify-center rounded-full  text-center font-semibold bg-red-700">
              <BiTrendingDown  class="relative z-10   leading-pro bg-gradient-to-tl from-white   bg-clip-text text-white " />
                </span> }
            
            <div class="mr-11 pt-1.4 lg:max-w-120 relative -top-1.5 w-auto">
                <h6 class="mb-0 text-sm font-semibold leading-normal dark:text-white text-slate-700">{item.amount}$  {item.reason}  </h6>
                <p class="mt-1 mb-0 text-xs font-semibold leading-tight text-slate-400">{item.created_at}</p>
            </div>
        </div>
    )
}
