import React from "react";
import { NavLink } from "react-router-dom";
import { FiPackage } from "react-icons/fi"; // أيقونة مفرغة
import { AiOutlineContainer } from "react-icons/ai"; // استبدال الأيقونة
import { AiOutlineWallet } from "react-icons/ai"; // استبدال الأيقونة
import { AiOutlineDashboard } from "react-icons/ai"; // استبدال الأيقونة

function BottomBar({ toggleSidebar, currentSidebarTab }) {
  return (
    <div className="fixed w-full h-16 max-w-lg -translate-x-1/2 bg-white border border-gray-200 rounded-sm bottom-0 left-1/2 dark:bg-gray-800 dark:text-gray-200 dark:border-gray-600 shadow-md" style={{ zIndex: "997" }}>
      <div className="grid h-full max-w-lg grid-cols-4 mx-auto">
        <NavLink
          to={"/packages"}
          className={({ isActive }) =>
            isActive
              ? "inline-flex flex-col items-center justify-center px-5 rounded-lg group text-blue-400 dark:text-blue-300"
              : "inline-flex flex-col items-center justify-center px-5 rounded-lg group text-gray-700 dark:text-gray-300"
          }
        >
          <FiPackage className="w-6 h-6" /> استثمار
        </NavLink>
        <NavLink
          to={"/my-packages"}
          className={({ isActive }) =>
            isActive
              ? "inline-flex flex-col items-center justify-center px-5 rounded-lg group text-blue-400 dark:text-blue-300"
              : "inline-flex flex-col items-center justify-center px-5 rounded-lg group text-gray-700 dark:text-gray-300"
          }
        >
          <AiOutlineContainer className="w-6 h-6" /> باقاتي
        </NavLink>
        <NavLink
          to={"/my-wallet"}
          className={({ isActive }) =>
            isActive
              ? "inline-flex flex-col items-center justify-center px-5 rounded-lg group text-blue-400 dark:text-blue-300"
              : "inline-flex flex-col items-center justify-center px-5 rounded-lg group text-gray-700 dark:text-gray-300"
          }
        >
          <AiOutlineWallet className="w-6 h-6" /> محفظتي
        </NavLink>
        <NavLink
          to={"/dashboard"}
          className={({ isActive }) =>
            isActive
              ? "inline-flex flex-col items-center justify-center px-5 rounded-lg group text-blue-400 dark:text-blue-300"
              : "inline-flex flex-col items-center justify-center px-5 rounded-lg group text-gray-700 dark:text-gray-300"
          }
        >
          <AiOutlineDashboard className="w-6 h-6" /> الرئيسية
        </NavLink>
      </div>
    </div>
  );
}

export default BottomBar;
