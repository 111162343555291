import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { HomeContext } from '../../contexts/HomeContext';
import LoadingCircle from '../../components/LoadingCircle';
import { NavLink } from 'react-router-dom';

function DepositHistory() {
  const { getDepositHistory, depositHistory } = useContext(HomeContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDepositHistory()
      .then(response => {
        setLoading(false);
        console.log(response);
      })
      .catch(error => {
        if (error.response?.data) {
          toast.error("An error occurred retrieving data: " + error.response.data.message);
          console.log(error.response.data.message);
        } else {
          toast.error("An unknown error has occurred");
          console.log(error);
        }
      });
  }, []);

  if (loading) {
    return <LoadingCircle />;
  }

  return (
    <div className="w-full px-6 py-6 mx-auto">
      <div className="flex flex-wrap -mx-3 h-screen">
        <div className="flex-none w-full max-w-full px-3">
          <div className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
            <div className="p-6 pb-0 mb-0 border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
              <h1 className="dark:text-white">طلبات الايداع</h1>
            </div>
            <div className="flex-auto px-0 pt-0 pb-2">
              <div className="p-0 overflow-x-auto min-h-[500px]">
                <div className="m-5">
                  <NavLink
                    to="/deposit-methods"
                    className="text-white bg-yellow-400 hover:bg-yellow-600 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-yellow-400 dark:hover:bg-yellow-600 focus:outline-none dark:focus:ring-yellow-600"
                  >
                    اضافة ايداع جديد
                  </NavLink>
                </div>

                {/* Table view for larger screens */}
                <div className="hidden md:block">
                  <table className="items-center w-full mb-0 align-top border-collapse dark:border-white/40 text-slate-500">
                    <thead className="align-bottom">
                      <tr>
                        <th className="px-6 py-3">id</th>
                        <th className="px-6 py-3">المبلغ</th>
                        <th className="px-6 py-3">الحالة</th>
                        <th className="px-6 py-3">رقم الحوالة</th>
                        <th className="px-6 py-3">طريقة الدفع</th>
                        <th className="px-6 py-3">ملاحظة الحالة  </th>
                        <th className="px-6 py-3">ملاحظة</th>
                        <th className="px-6 py-3">التاريخ</th>
                        <th className="px-6 py-3">اخر تعديل</th>
                      </tr>
                    </thead>
                    <tbody>
                      {depositHistory?.map((depo) => (
                        <tr key={depo.id} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{depo.id}</th>
                          <td className="px-6 py-4">{depo.amount}</td>
                          <td className="px-6 py-4">{depo.status}</td>
                          <td className="px-6 py-4">{depo.transaction_number}</td>
                          <td className="px-6 py-4">{depo.deposit_method_id}</td>
                          <td className="px-6 py-4">{depo.status_message}</td>
                          <td className="px-6 py-4">{depo.note}</td>
                          <td className="px-6 py-4">{depo.created_at}</td>
                          <td className="px-6 py-4">{depo.updated_at}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* Card view for mobile screens */}
                <div className="md:hidden space-y-4 mb-20 p-5">
                  {depositHistory?.map((depo) => (
                    <div key={depo.id} className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg shadow-md">
                      <p className="text-gray-800 dark:text-white font-semibold">id: {depo.id}</p>
                      <p className="text-gray-500 dark:text-gray-400">المبلغ: {depo.amount}</p>
                      <p className="text-gray-500 dark:text-gray-400">الحالة: {depo.status}</p>
                      <p className="text-gray-500 dark:text-gray-400">رقم الحوالة: {depo.transaction_number}</p>
                      <p className="text-gray-500 dark:text-gray-400">طريقة الدفع: {depo.deposit_method_id}</p>
                      <p className="text-gray-500 dark:text-gray-400">ملاحظة الحالة: {depo.status_message}</p>
                      <p className="text-gray-500 dark:text-gray-400">ملاحظة: {depo.note}</p>
                      <p className="text-gray-500 dark:text-gray-400">التاريخ: {depo.created_at}</p>
                      <p className="text-gray-500 dark:text-gray-400">اخر تعديل: {depo.updated_at}</p>
                    </div>
                  ))}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DepositHistory;
