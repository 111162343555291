import React from 'react';

function LoadingCircle(props) {
    return (
        <div className="flex items-center justify-center w-full h-screen border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
            <div className="px-3 py-1 text-xs font-medium leading-none text-center text-blue-800 bg-blue-200 rounded-full animate-pulse dark:bg-blue-900 dark:text-blue-200">
            <img src="/load.svg" className='w-8 h-8' />
            </div>
        </div>
    );
}

export default LoadingCircle;