import React, { useState } from "react";
import OneDepositMethod from "./OneDepositMethod";

function MultiDepositMethods({ depositMethods }) {


  try {
    if (depositMethods.length === 0) {
      return <div>
        <p> is Empty </p>
      </div>;
    }
  } catch (error) { }

  return ( 

    <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-7"> 
        
          {depositMethods?.map((depo) => ( 
              <OneDepositMethod depo={depo} /> 
          ))}
        
    </div>
  );
}


export default MultiDepositMethods;