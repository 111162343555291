import React, { useEffect } from 'react'
 import { CheckSession } from '../../functions/forms';
import Cookies from 'js-cookie';

function NotLogged() {
   
    useEffect(() => {
      if (!Cookies.get('userLoggedIn')) {
        window.location.href = "/";
      } 
        
    }, [])
    
  return (
    <div>يجب تسجيل الدخول</div>
  )
}

export default NotLogged