import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext, useAuthContext } from "../../contexts/AuthContext";
import { AiOutlinePhone } from "react-icons/ai";
import { BsInfoCircle } from "react-icons/bs";
import { FaRegUserCircle } from "react-icons/fa";
import { AiFillDashboard } from "react-icons/ai";
import { FiPackage } from "react-icons/fi";
import { AiOutlineTransaction } from "react-icons/ai";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import { GiCardboardBox } from "react-icons/gi";
import { FaWallet } from "react-icons/fa";
import DepositWithdrawButtons from "../DepositWithdrawButtons";


function Menu({ closeTabByClick, isSidebarOpen, toggleSidebar }) {
  const { userLoggedIn } = useAuthContext();
  const { logout } = useContext(AuthContext);

  return (
    <>
      <aside
        style={{
          scrollbarWidth: 'thin', // لأجهزة Firefox
          scrollbarColor: '#888 #f1f1f1', // لأجهزة Firefox
          zIndex: '999'
        }}
        className={`fixed inset-y-0   pb-24 flex-wrap items-center justify-between block px-4 antialiased transition-transform duration-200 ${isSidebarOpen ? "translate-x-0" : "translate-x-full"
          } bg-white border-0 shadow-none dark:shadow-none dark:bg-slate-850   overflow-auto ease-nav-brand   xl:translate-x-0
    w-3/4 sm:w-64 lg:w-72`}
        aria-expanded={isSidebarOpen}
      >

        <div className="h-19">
          <i
            className="absolute top-0 right-0 p-4  overflow-auto opacity-50 cursor-pointer fas fa-times dark:text-white text-slate-400 xl:hidden"
            onClick={toggleSidebar}
          ></i>   <div class="h-19">
            <i onClick={toggleSidebar} class="absolute top-0 right-0 p-4 opacity-50 cursor-pointer fas fa-times dark:text-white text-slate-400 xl:hidden" sidenav-close></i>

            <a onClick={closeTabByClick} href="#" className="block px-8 py-6 m-0 text-sm whitespace-nowrap text-slate-700">
              <img src="logo/logo_name.png" class="inline h-full max-w-full transition-all duration-200 dark:hidden ease-nav-brand max-h-10" alt="main_logo" />
              <img src="logo/logo_name.png" class="hidden h-full max-w-full transition-all duration-200 dark:inline ease-nav-brand max-h-10" alt="main_logo" />

              {/* اسم الموقع */}
              <span className="text-xl font-semibold text-gray-900 dark:text-white">
                Digital Money
              </span> </a>

          </div>
          <DepositWithdrawButtons />
          <div class="items-center block w-auto max-h-screen grow basis-full mb-8">
            <ul class="flex flex-col pl-0 mb-0">

              {userLoggedIn ? (
                <>

                  <li class="mt-0.5 w-full">
                    <NavLink
                      onClick={closeTabByClick}
                      to="/dashboard"
                      className={({ isActive }) =>
                        isActive
                          ? "py-2.7 bg-blue-500/13 dark:text-white dark:opacity-80 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors"
                          : "py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors dark:text-white dark:opacity-80 "
                      }
                    >
                      <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                        <AiFillDashboard className="relative top-0  leading-normal text-blue-500 w-6 h-6" />
                      </div>
                      <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">لوحة التحكم</span>
                    </NavLink>
                  </li>
                  <li class="mt-0.5 w-full">
                    <NavLink
                      onClick={closeTabByClick}
                      exact
                      to={"/my-wallet"}
                      className={({ isActive }) =>
                        isActive
                          ? "flex items-center dark:text-white w-full space-x-2 text-slate-100 rounded-lg  p-3 bg-blue-700 font-bold "
                          : "flex items-center dark:text-white w-full space-x-2 text-slate-900 rounded-lg  p-3 "
                      }
                    >
                      <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center fill-current stroke-0 text-center xl:p-2.5">
                        <FaWallet className="relative top-0  leading-normal text-emerald-500 w-6 h-6" />
                      </div>
                      <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">محفظتي</span>
                    </NavLink>
                  </li>


                  <li class="mt-0.5 w-full">
                    <NavLink
                      onClick={closeTabByClick}
                      exact
                      to="/packages"
                      className={({ isActive }) =>
                        isActive
                          ? "flex items-center dark:text-white w-full space-x-2 text-slate-100 rounded-lg  p-3 bg-blue-700 font-bold "
                          : "flex items-center dark:text-white w-full space-x-2 text-slate-900 rounded-lg  p-3 "
                      }
                    >
                      <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center fill-current stroke-0 text-center xl:p-2.5">
                        <FiPackage className="relative top-0  leading-normal text-emerald-500 w-6 h-6" />
                      </div>
                      <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">استثمار</span>
                    </NavLink>
                  </li>
                  <li class="mt-0.5 w-full">
                    <NavLink
                      onClick={closeTabByClick}
                      exact
                      to="/my-packages"
                      className={({ isActive }) =>
                        isActive
                          ? "flex items-center w-full dark:text-white space-x-2 text-slate-100 rounded-lg  p-3 bg-blue-700 font-bold "
                          : "flex items-center w-full dark:text-white space-x-2 text-slate-900 rounded-lg  p-3 "
                      }
                    >
                      <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                        <GiCardboardBox className="relative top-0  leading-normal text-blue-500 w-6 h-6" />
                      </div>
                      <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">باقاتي النشطة</span>
                    </NavLink>
                  </li>

                  <li class="mt-0.5 w-full">
                    <NavLink
                      onClick={closeTabByClick}
                      exact
                      class=" dark:text-white dark:opacity-80 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors"
                      to="/deposit-history"
                      className={({ isActive }) =>
                        isActive
                          ? "flex items-center w-full dark:text-white space-x-2 text-slate-100 rounded-lg  p-3 bg-blue-700 font-bold "
                          : "flex items-center w-full dark:text-white space-x-2 text-slate-900 rounded-lg  p-3 "
                      }
                    >
                      <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                        <AiOutlineTransaction className="relative top-0  leading-normal text-cyan-500 w-6 h-6" />
                      </div>
                      <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">طلبات الايداع</span>
                    </NavLink>
                  </li>

                  <li class="mt-0.5 w-full">
                    <NavLink
                      onClick={closeTabByClick}
                      exact
                      class=" dark:text-white dark:opacity-80 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors"
                      to="/withdraw-history"
                      className={({ isActive }) =>
                        isActive
                          ? "flex items-center w-full dark:text-white space-x-2 text-slate-100 rounded-lg  p-3 bg-blue-700 font-bold "
                          : "flex items-center w-full dark:text-white space-x-2 text-slate-900 rounded-lg  p-3 "
                      }
                    >
                      <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                        <HiOutlineCurrencyDollar className="relative top-0 leading-normal text-cyan-500 w-6 h-6" />
                      </div>
                      <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">طلبات السحب</span>
                    </NavLink>
                  </li>


                </>) : <></>}
              <li class="mt-0.5 w-full">

                <NavLink
                  onClick={closeTabByClick}
                  exact
                  to="/about-us"
                  className={({ isActive }) =>
                    isActive
                      ? "dark:text-white dark:opacity-80 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors "
                      : "dark:text-white dark:opacity-80 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors"
                  }
                >  <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <BsInfoCircle class="relative top-0  leading-normal text-slate-700 w-6 h-6" />
                  </div>

                  <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">من نحن</span>
                </NavLink>

              </li>
              <li class="mt-0.5 w-full">

                <NavLink
                  onClick={closeTabByClick}
                  exact
                  to="/connect-us"
                  className={({ isActive }) =>
                    isActive
                      ? "dark:text-white dark:opacity-80 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors"
                      : "dark:text-white dark:opacity-80 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors"
                  }
                >
                  <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <AiOutlinePhone class="relative top-0  leading-normal text-orange-500 w-6 h-6" />
                  </div>

                  <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">اتصل بنا</span>

                </NavLink>
              </li>

              {userLoggedIn ? (
                <>
                  <li class="mt-0.5 w-full">
                    <NavLink
                      onClick={closeTabByClick}
                      exact
                      to="/profile"
                      className={({ isActive }) =>
                        isActive
                          ? "dark:text-white dark:opacity-80 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors "
                          : "dark:text-white dark:opacity-80 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors"
                      }
                    >
                      <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                        <FaRegUserCircle class="relative top-0  leading-normal text-orange-500  w-6 h-6" />
                      </div>

                      <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">الملف الشخصي</span>

                    </NavLink>


                  </li>

                  <li class="mt-0.5 w-full">
                    <div className='grid pt-6'>
                      <button
                        className="  focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                        role="menuitem"
                        onClick={logout}
                      >
                        تسجيل خروج
                      </button>
                    </div>

                  </li></>)
                :
                (<>

                  <li class="mt-0.5 w-full">
                    <NavLink
                      onClick={closeTabByClick}
                      to="/login"
                      className=" dark:text-white dark:opacity-80 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors"
                    >  <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                        <i class="relative top-0 text-sm leading-normal text-orange-500 ni ni-single-copy-04"></i>
                      </div>
                      <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">دخول</span>

                    </NavLink>
                  </li>

                  <li class="mt-0.5 w-full">
                    <NavLink
                      onClick={closeTabByClick}
                      to="/register"
                      className="dark:text-white dark:opacity-80 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors"
                    >
                      <div class="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                        <i class="relative top-0 text-sm leading-normal text-cyan-500 ni ni-collection"></i>
                      </div>
                      <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">اشتراك</span>

                    </NavLink>
                  </li>
                </>
                )}

            </ul>
            <div className="flex justify-between items-center p-4 border-t mt-8">
              <NavLink
                onClick={closeTabByClick}
                to="/terms-of-use"
                className="text-sm text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition"
              >
                شروط الاستخدام
              </NavLink>
              <NavLink
                onClick={closeTabByClick}
                to="/privacy-policy"
                className="text-sm text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition"
              >
                سياسة الخصوصية
              </NavLink>
            </div>
          </div>
        </div>


      </aside>
    </>
  );
}

export default Menu;
