
import React, { useContext, useEffect, useState } from "react";
import { HomeContext } from "../../contexts/HomeContext";
import toast from "react-hot-toast";
import LoadingCircle from "../../components/LoadingCircle";
import TopCard from "../../components/cards/TopCard";
import { BiTrendingUp } from "react-icons/bi";
import Trending from "../../components/cards/Trending";
import { NavLink } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { AuthContext } from "../../contexts/AuthContext";
import Cookies from "js-cookie";
import { AiOutlineArrowRight } from 'react-icons/ai'; // استيراد أيقونة السهم
import DepositWithdrawButtons from "../../components/DepositWithdrawButtons";

function Dashboard() {
  const { getMyHome, myHome } = useContext(HomeContext);
  var user = JSON.parse(Cookies.get("user"));
  const [loading, setLoading] = useState(true);
  const [copied, setCopied] = useState(false);
  const [inviteLink, setInviteLink] = useState(false);




  const handleCopy = () => {
    setCopied(true);
    // Optional: Show a toast or notification indicating that the link has been copied
    // toast.success("Link copied!");
  };
  useEffect(() => {
    if (user) {
      setInviteLink(`https://digitalmoney2024.com/register?referral_code=${user.referral_code}`);
    }

    getMyHome()
      .then((response) => {
        console.log(response);
        setLoading(false)
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response?.data) {
          toast.error(
            "An error occurred retrieving data" + error.response?.data?.message
          );
          console.log(error.response.data.message);
        } else {
          toast.error("An unknown error has occurred");
          console.log(error);
        }
      });

  }, []);

  if (loading) {
    return <LoadingCircle />;
  }

  return (
    <>


      <div className="w-full px-6 py-6 mx-auto">
        <div className="flex flex-wrap -mx-3">
          <TopCard title="رصيدك" value={`${myHome.balnace}$`} icon="ni leading-none ni-money-coins text-lg relative top-3.5 text-white" />
          <TopCard title="ارباحك" value={`${myHome.profits}$`} icon="ni leading-none ni-world text-lg relative top-3.5 text-white" />
          <TopCard title="مسحوباتك" value={`${myHome.withdraws}$`} icon="ni leading-none ni-paper-diploma text-lg relative top-3.5 text-white" />
          <TopCard title="عدد باقاتك" value={`${myHome.subscriptionCount}`} icon="ni leading-none ni-paper-diploma text-lg relative top-3.5 text-white" />
        </div>
      </div>

      {/* buttons  */}


      <div class="flex flex-wrap my-6 -mx-3">

        <div className="w-full px-3 md:w-1/2   my-6">
          <div className="relative flex flex-col min-w-0 break-words bg-white border-0 rounded-2xl shadow-xl border-black/12.5 dark:bg-slate-850 dark:shadow-dark-xl">
            <div className="p-6 pb-0 border-b-0 border-black/12.5 rounded-t-2xl">
              <DepositWithdrawButtons />
            </div>
          </div>
        </div>


        <div class="w-full md:w-1/2 px-3 mt-0 my-6   ">
          <div class="border-black/12.5 dark:bg-slate-850 dark:shadow-dark-xl shadow-xl relative flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
            <div class="border-black/12.5 mb-0 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
              <div className="relative m-6 text-yellow-400" >
                {inviteLink ? <>
                  <p className="mr-2 text-sm">{myHome.inviteLinkDescription}</p>
                  <div className="flex ">
                    <input
                      type="text"
                      className="flex-grow px-4 py-2.5 mb-2 mr-2 text-sm rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                      value={inviteLink}
                      readOnly
                    />
                    <CopyToClipboard text={inviteLink} onCopy={handleCopy}>
                      <button
                        className="px-4 py-2.5 mb-2 font-bold text-white bg-yellow-400 rounded-lg hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                        type="button"
                      >
                        {copied ? "تم النسخ" : "نسخ"}
                      </button>
                    </CopyToClipboard> </div>
                </> : <>ليس لديك كود دعوة تواصل مع الدعم من خلال صفحة اتصل بنا  <NavLink
                  to={"/connect-us"}
                  className="mr-2     px-4 py-2.5 mb-2 ml-2 font-bold leading-normal text-center bg-white text-yellow-400  capitalize align-middle transition-all bg-transparent border border-yellow-400  border-solid rounded-lg cursor-pointer text-sm  xl-max:bg-gradient-to-tl xl-max:from-yellow-400  xl-max:to-violet-500 xl-max:text-white xl-max:border-0 hover:-translate-y-px  dark:bg-yellow-400 dark:text-white dark:border-0 hover:shadow-xs active:opacity-85 ease-in tracking-tight-rem shadow-md bg-150 bg-x-25 bg-none hover:border-yellow-400 "
                >
                  اتصل بتا
                </NavLink></>}
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* history */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gqp-4 mb-28">
        <div className="w-full max-w-full px-3 py-3  ">
          <div className="border-black/12.5 shadow-xl relative flex h-full min-w-0 dark:bg-slate-850 dark:shadow-dark-xl flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
            <div className="border-black/12.5 mb-0 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
              <h6 className="dark:text-white text-2xl">سجل الرصيد الوارد</h6>
            </div>
            <div className="flex-auto p-4">
              <div className="before:border-l-solid relative before:absolute before:top-0 before:right-4 before:h-full before:border-l-2 before:border-l-slate-100 before:content-[''] before:lg:-mr-px">
                {myHome.historyBalanceUp?.map((item) => (<Trending item={item} />))}

              </div>
            </div>
          </div>
        </div>
        <div className="w-full max-w-full px-3 py-3  ">
          <div className="border-black/12.5 shadow-xl relative flex h-full min-w-0 dark:bg-slate-850 dark:shadow-dark-xl flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
            <div className="border-black/12.5 mb-0 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
              <h6 className="dark:text-white  text-2xl">سجل الرصيد الصادر</h6>

            </div>
            <div className="flex-auto p-4">
              <div className="before:border-l-solid relative before:absolute before:top-0 before:right-4 before:h-full before:border-l-2 before:border-l-slate-100 before:content-[''] before:lg:-mr-px">
                {myHome.historyBalanceDown?.map((item) => (<Trending item={item} />))}

              </div>
            </div>
          </div>
        </div>
      </div>



    </>
  );
}

export default Dashboard;
