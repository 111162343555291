// PrivacyPolicy.js
import React from 'react';

function PrivacyPolicy() {
  return (
    <div class="w-full px-6 py-6 mx-auto">

      <div class="relative  p-5 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
        <h1 className="text-2xl font-bold mb-4">سياسة الخصوصية</h1>
        <p>
          في موقع <strong>digitalmoney2024.com</strong>، نحن نقدر خصوصيتك ونلتزم بحماية معلوماتك الشخصية.
          هذه السياسة توضح نوعية البيانات التي نقوم بتخزينها وكيفية استخدامها.
        </p>

        <h2 className="text-xl font-bold mt-4">المعلومات التي نجمعها</h2>
        <p>
          نحن نجمع فقط المعلومات الأساسية من مستخدمينا، والتي تشمل الاسم وعنوان البريد الإلكتروني. نحن لا نجمع أو نخزن أي بيانات حساسة أخرى.
        </p>

        <h2 className="text-xl font-bold mt-4">كيف نستخدم المعلومات</h2>
        <p>
          نستخدم بياناتك للتواصل معك وتقديم خدماتنا الاستثمارية في العملات المشفرة فقط. نحن لا نشارك بياناتك مع أي طرف ثالث لأغراض تسويقية.
        </p>

        <h2 className="text-xl font-bold mt-4">أمان البيانات</h2>
        <p>
          نحن نتخذ إجراءات أمنية لحماية بياناتك الشخصية من الوصول غير المصرح به أو التغيير أو الكشف. ومع ذلك، لا يوجد نظام أمني يمكن أن يضمن الأمان المطلق.
        </p>

        <h2 className="text-xl font-bold mt-4">حقوق المستخدمين</h2>
        <p>
          لديك الحق في طلب حذف بياناتك أو تحديثها في أي وقت عن طريق التواصل معنا عبر البريد الإلكتروني.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
