"use client";
import { createContext, useContext, useState } from "react";
import api from "../axios/api";

export const HomeContext = createContext();

export const HomeProvider = ({ children }) => {  
   
  const [dataUser, setDataUser] = useState();
  const [baseData, setBaseData] = useState(null); 
  const [depositMehods, setDepositMehods] = useState(null); 
  const [withdrawMehods, setWithdrawMehods] = useState(null); 
  const [depositHistory, setDepositHistory] = useState(null); 
  const [withdrawHistory, setWithdrawHistory] = useState(null); 
  const [packages, setPackages] = useState(null); 
  const [myPackages, setMyPackages] = useState(null); 
  const [myHome, setMyHome] = useState(null);
  const [myWallet, setMyWallet] = useState(null);
  
  // notes
  // get home page
  const getHome = async (firstLoad) => {
    
    // const response = await api.get("/home?page=" + page);
    // if (firstLoad ) { 
    //   setNotes(response.data.data.data);
    // } else { 
    //   setNotes((previousNotes) => [
    //     ...previousNotes,
    //     ...response.data.data.data,
    //   ]);
    // }

    // console.log(response.data.data.data);
  };
 
  const getBaseDataLogged = async (userLoggedIn) => {
    if(baseData){
      return true;
    }
    var route = '/base_data';
    if(userLoggedIn)  route = "/base_data_logged";
    const response = await api.get(route);
    setBaseData(response.data.data);  
    // console.log("base_data_logged = ", response.data.data);
  };
  
 
  // const deleteSection = async (id) => {
  //   const response = await api.delete("/sections/" + id);
  //   return response.data;
  // };
 
  
  const getDepositMethods = async () => {
    const response = await api.get("/deposit_methods");
    console.log(response.data);
    setDepositMehods(response.data.data);
    return response.data.data;
    // console.log(response.data.data);
  };

  const getMyHome  = async () => {
    const response = await api.get("/my_home");
    console.log(response.data.data);
    setMyHome(response.data.data);
    return response.data.data; 
  };
  const getWithdrawMethods = async () => {
    const response = await api.get("/withdraw_methods");
    console.log(response.data);
    setWithdrawMehods(response.data.data);
    return response.data.data;
    // console.log(response.data.data);
  };
  
  const getDepositHistory = async () => {
    const response = await api.get("/deposit_history");
    console.log(response.data);
    setDepositHistory(response.data.data);
    return response.data.data;
    // console.log(response.data.data);
  };
  
  const getPackages = async () => {
    const response = await api.get("/packages");
    console.log(response.data);
    setPackages(response.data.data);
    return response.data.data;
    // console.log(response.data.data);
  };

  const getMyPackages = async () => {
    const response = await api.get("/my_packages");
    console.log(response.data);
    setMyPackages(response.data.data);
    return response.data.data;
    // console.log(response.data.data);
  };
  
  
  const cancelPackage = async (id) => { 
    const response = await api.get("/packages/cancel/"+id); 
    console.log(response.data);
    setMyPackages(response.data.data);
    return response.data;
  };


  const getWithdrawHistory = async () => {
    const response = await api.get("/withdraw_history");
    console.log(response.data);
    setWithdrawHistory(response.data.data);
    return response.data.data;
    // console.log(response.data.data);
  };
  const getProfile = async () => {
    const response = await api.get("/users/my_profile");
    setDataUser(response.data.data);
    // console.log(response.data.data);
  };

  const updateProfile = async (data) => {
    const response = await api.post("/users/update_profile", data);
    // console.log(response.data);
    return response.data;
  };

  const updatePassword = async (data) => {
    const response = await api.post("/users/update_password", data);
    // console.log(response.data);
    return response.data;
  };
  
  const getMethodById = async (id) => {
      
    const response = await api.get("/deposit_methods/" + id); 
      console.log(response.data.data);
    return response.data.data;
  };

  const getMethodWithdrawById = async (id) => {
      
    const response = await api.get("/withdraw_methods/" + id); 
      console.log(response.data.data);
    return response.data.data;
  };

  const requestDeposit = async (data) => { 
    const response = await api.post("/request_deposit", data); 
    return response.data;
  };

  const requestSubscrip = async (data) => { 
    const response = await api.post("/packages/addPackageToUser", data); 
    return response.data;
  };

  const requestWithdraw = async (data) => { 
    const response = await api.post("/request_withdraw", data); 
    return response.data;
  };

  const markAllNotificationsAsRead = async () => { 
    const response = await api.post("/markAllNotificationsAsRead"); 
    setMyHome(response.data.data);
    return response.data;
  };
  
  const markNotificationAsRead = async (id) => { 
    const response = await api.post("/markNotificationAsRead/"+id); 
    setMyHome(response.data.data);
    return response.data;
  };
  
     
  const getWallet = async () => {
    const response = await api.get("/my_wallet");
    console.log(response.data);
    setMyWallet(response.data.data);
    return response.data.data;
    // console.log(response.data.data);
  };
  
  return (
    <HomeContext.Provider
      value={{
        getProfile,
        dataUser,
        updateProfile,
        updatePassword, 
        getHome,    
         getBaseDataLogged,
         baseData,    
         getDepositMethods,
         depositMehods,
         setDepositMehods,
         getMethodById,
         getMethodWithdrawById,
         requestDeposit,
         requestWithdraw,
         getWithdrawMethods,
         withdrawMehods,
         getDepositHistory,
         getWithdrawHistory,
         depositHistory,
         withdrawHistory,
         getPackages,
         cancelPackage,
         packages,
         requestSubscrip,
         getMyPackages,
         myPackages,
         getMyHome,
         myHome,
         markAllNotificationsAsRead,
         markNotificationAsRead,
         myWallet,
         getWallet
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

export const useHomeContext = () => useContext(HomeContext);
