import React, { useContext, useEffect, useState } from "react";
import { HomeContext } from "../../contexts/HomeContext";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import LoadingCircle from "../../components/LoadingCircle";

function OnlineTransfer() {
    const { id } = useParams();
    const { getMethodById } = useContext(HomeContext);

    const [depositMethod, setDepositMethod] = useState(null);
    const [selectedCryptoType, setSelectedCryptoType] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getDepositMethod();
    }, [id]);

    const getDepositMethod = () => {
        getMethodById(id)
            .then((response) => {
                setDepositMethod(response);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                if (error.response?.data) {
                    toast.error("An error occurred retrieving data: " + error.response.data.message);
                } else {
                    toast.error("An unknown error has occurred");
                }
            });
    };

    if (loading) {
        return <LoadingCircle />;
    }

    if (!depositMethod) {
        return <div>No deposit method found</div>;
    }

    const handleCryptoTypeChange = (event) => {
        const selectedId = parseInt(event.target.value, 10);
        const selectedCrypto = depositMethod.type_cryptos.find(
            (crypto) => crypto.id === selectedId
        );
        setSelectedCryptoType(selectedCrypto);
    };

    return (
        <div className="w-full px-6 py-6 mx-auto">
            <div className="flex flex-wrap -mx-3 h-screen">
                <div className="flex-none w-full max-w-full px-3">
                    <div className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">

                        <div className="card shadow-sm">
                            <div className="card-body">
                                <h2 className="card-title text-center">تحويل عبر: {depositMethod.name}</h2>
                                {depositMethod.type_cryptos.length > 0 && (
                                    <div className="mb-4">
                                        <label htmlFor="cryptoType" className="form-label">اختر شبكة التحويل:</label>
                                        <select
                                            id="cryptoType"
                                            onChange={handleCryptoTypeChange}
                                            className="form-select"
                                            defaultValue=""
                                        >
                                            <option value="" disabled>
                                                اختر شبكة
                                            </option>
                                            {depositMethod.type_cryptos.map((crypto) => (
                                                <option key={crypto.id} value={crypto.id}>
                                                    {crypto.payment_type}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}

                                {selectedCryptoType ? (
                                    <div className="crypto-details">
                                        <h3 className="text-center">المعلومات المطلوبة</h3>
                                        <div className="text-center">
                                            <p>أرسل {selectedCryptoType.main_name} إلى هذا العنوان عن طريق شبكة {selectedCryptoType.payment_type} حصراً</p>
                                            <img
                                                src={`https://api.qrserver.com/v1/create-qr-code/?data=${depositMethod.qr_code_url}`}
                                                alt="QR Code"
                                                className="img-fluid mb-3"
                                            />
                                            <p><strong>العنوان:</strong> {depositMethod.address}</p>
                                            <p><strong>الحد الأدنى للإيداع:</strong> {selectedCryptoType.min_amount_accepted} USDT</p>
                                        </div>

                                        <p>{selectedCryptoType.note && `ملاحظة: ${selectedCryptoType.note}`}</p>
                                        <img
                                            src={`https://admin.usdtbig.online/images/${selectedCryptoType.image}`}
                                            alt={selectedCryptoType.payment_type}
                                            className="img-fluid mb-3"
                                        />
                                    </div>
                                ) : (
                                    <div className="alert alert-warning text-center">
                                        اختر شبكة - select network
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OnlineTransfer;
