import React, { useContext, useEffect, useState } from 'react';
import { HomeContext } from '../../contexts/HomeContext';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import LoadingCircle from '../../components/LoadingCircle';
import WithdrawOnline from './WithdrawOnline';
import WithdrawCrypto from './WithdrawCrypto';
import WithdrawManual from './WithdrawManual'; // إضافة المكون الجديد

function AddRequestWithdraw() {
  const { getMethodWithdrawById } = useContext(HomeContext);
  const [method, setMethod] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    getMethod();
  }, [id]);

  const getMethod = () => {
    getMethodWithdrawById(id)
      .then((response) => {
        setLoading(false);
        if (response == null) {
          window.location.href = "/error/404";
        }
        setMethod(response);
      })
      .catch((error) => {
        toast.error("An error occurred retrieving data");
      });
  };

  if (loading || method === null) {
    return <LoadingCircle />;
  }

  return (
    <div className="w-full px-6 py-6 mx-auto">
      <div className="flex flex-wrap -mx-3 h-screen">
        <div className="flex-none w-full max-w-full px-3">
          <div className="relative flex p-6 flex-col min-w-0 mb-6 bg-white border-0 shadow-xl rounded-2xl">
            <h1  className="text-2xl font-semibold mb-6 text-gray-800 dark:text-white">اضافة طلب سحب</h1>
            {method.type === "1" ? (
              <WithdrawOnline method={method} />
            ) : method.type === "3" ? (
              <WithdrawCrypto method={method} />
            ) : method.type === "2" ? (
              <WithdrawManual method={method} />
            ) : (
              <p>نوع السحب غير مدعوم</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddRequestWithdraw;
